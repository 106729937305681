<template>
    <main>
        <h1 style="font-size:108px;text-shadow: 1px 1px 3px #ddd;color:#fff">404</h1>
    </main>
</template>

<script>
export default {
    name: "404"
}
</script>

<style scoped>

</style>